import { LeagueWithAttendace } from "Models/Statistics/LeagueWithAttendance";
import BaseApi from "./BaseApi";
import { TeamWithAttendace } from "Models/Statistics/TeamWithAttandance";
import { SeenPlayer } from "Models/Statistics/SeenPlayer";

export default class StatisticsApi extends BaseApi {
    private baseUrl = `${process.env.REACT_APP_BASE_URL}/api`;

    public getMostAttendedLeagues(): Promise<Array<LeagueWithAttendace>> {
        return fetch(`${this.baseUrl}/Statistics/mostAttendedLeagues`, {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public getMostAttendedTeams(): Promise<Array<TeamWithAttendace>> {
        return fetch(`${this.baseUrl}/Statistics/mostAttendedTeams`, {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public getMostFrequentlySeenPlayers(): Promise<Array<SeenPlayer>> {
        return fetch(`${this.baseUrl}/Statistics/mostFrequentlySeenPlayers`, {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }
}