import { Button, Form, Input, message } from 'antd';
import AuthenticationApi from 'Api/AuthenticationApi';
import { useAuth } from 'Authentication/useAuth';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export function Register() {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        marginTop: '200px',
    };

    const style: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        marginTop: '30px'
    };

    const formStyle: React.CSSProperties = {
        width: '400px'
    };

    type RegisterFieldType = {
        username: string;
        mail: string;
        password: string;
        repeatedPassword: string;
    };

    const authenticationApi = new AuthenticationApi();
    const auth = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/');
        }
    }, [auth.isAuthenticated, navigate]);

    const onSubmit = async (register: RegisterFieldType) => {
        const registerResult = await authenticationApi.register(register.username, register.mail, register.password);
        if (!registerResult.ok) {
            messageApi.open({
                type: 'error',
                content: await registerResult.text()
            });
        } else {
            messageApi.open({
                type: 'success',
                content: 'Registration successful!'
            });

            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
    };

    return (
        <div style={containerStyle}>
            <h1>Register</h1>
            <div style={style}>
                {contextHolder}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    style={formStyle}
                >
                    <Form.Item<RegisterFieldType>
                        label="Email"
                        name="mail"
                        rules={[{ required: true, message: 'Please input your mail.' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<RegisterFieldType>
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username.' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<RegisterFieldType>
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password.' },
                            { min: 8, message: 'Password must be minimum 8 characters long.' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item<RegisterFieldType>
                        label="Repeat password"
                        name="repeatedPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Link to="/login">Already have an account? Click here to login.</Link>
        </div>
    )
}