import { List } from "antd";
import classNames from "classnames";
import ballIcon from 'Icons/ball.svg';
import styles from './Event.module.scss';
import { formatTime } from "Common/Formatters";
import { EventModel } from "Models/EventModel";

export interface GoalEventProps {
    event: EventModel;
    homeTeamId?: number;
    awayTeamId?: number;
}

export function GoalEvent(props: GoalEventProps) {
    const { event } = props;

    if (event.teamId === props.homeTeamId) {
        return (
            <List.Item style={{ justifyContent: 'start' }}>
                <span className={styles.marginRight5}>{formatTime(event.time)}</span>
                <img src={ballIcon} alt='goal' className={classNames(styles.marginRight5, styles.eventLogo)} />
                <span className={styles.marginRight5}>{event.player!.name}</span>
                {event.assist!.name && <span className={styles.marginRight5}>{`(${event.assist!.name})`}</span>}
            </List.Item>
        );
    } else if (event.teamId === props.awayTeamId) {
        return (
            <List.Item style={{ justifyContent: 'end' }}>
                <span className={styles.marginRight5}>{event.player!.name}</span>
                {event.assist!.name && <span className={styles.marginRight5}>{`(${event.assist!.name})`}</span>}
                <img src={ballIcon} alt='goal' className={classNames(styles.marginRight5, styles.eventLogo)} />
                <span className={styles.marginRight5}>{formatTime(event.time)}</span>
            </List.Item>
        );
    }

    return <></>;
}