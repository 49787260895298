import { Button, Checkbox, Form, Input, message } from 'antd';
import AuthenticationApi from 'Api/AuthenticationApi';
import { useAuth } from 'Authentication/useAuth';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export function Login() {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        marginTop: '200px',
    };

    const style: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        marginTop: '30px'
    };

    const formStyle: React.CSSProperties = {
        width: '400px'
    };

    type LoginFieldType = {
        username: string;
        password: string;
        remember: boolean;
    };

    const authenticationApi = new AuthenticationApi();
    const auth = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/');
        }
    }, [auth.isAuthenticated, navigate]);

    const onSubmit = async (login: LoginFieldType) => {
        try {
            const userDataModel = await authenticationApi.login(login.username, login.password);
            if (userDataModel) {
                auth.signIn({ ...userDataModel, rememberMe: login.remember });

                messageApi.open({
                    type: 'success',
                    content: 'Logged in!'
                });
            }
        } catch {
            messageApi.open({
                type: 'error',
                content: 'Incorrect username or password!',
            });
        }
    };

    return (
        <div style={containerStyle}>
            <h1>Login</h1>
            <div style={style}>
                {contextHolder}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    style={formStyle}
                >
                    <Form.Item<LoginFieldType>
                        label="Username/Email"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<LoginFieldType>
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item<LoginFieldType>
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{ offset: 8, span: 16 }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Link to="/register">Don't have an account? Click here to register.</Link>
            <Link to="/requestPasswordReset">Don't remember your password? Click here to reset.</Link>
        </div>
    )
}