import { Card, List, Col, Row, Skeleton, Divider } from "antd";
import classNames from "classnames";
import { LineupModel } from "Models/LineupModel";
import { ReactNode } from "react";
import styles from './Lineups.module.scss';

export interface LineupsProps {
    lineups: LineupModel[];
    isLoading: boolean;
}

export function Lineups(props: LineupsProps) {
    const prepareFirstSquad = (lineups?: LineupModel[]) => {
        if (!lineups) {
            return [];
        }

        const concatenatedData = [];

        for (let index = 0; index < 11; index++) {
            concatenatedData.push({
                home: {
                    shirtNumber: lineups[0].startXI[index].player.number,
                    name: lineups[0].startXI[index].player.name,
                },
                away: {
                    shirtNumber: lineups[1].startXI[index].player.number,
                    name: lineups[1].startXI[index].player.name,
                },
            });
        }

        return concatenatedData;
    };

    const prepareBench = (lineups?: LineupModel[]) => {
        if (!lineups) {
            return [];
        }

        const max = Math.max(lineups[0].substitutes.length, lineups[1].substitutes.length);
        const concatenatedData = [];

        for (let index = 0; index < max; index++) {
            const home = lineups[0].substitutes.length >= index + 1 ?
                {
                    shirtNumber: lineups[0].substitutes[index].player.number,
                    name: lineups[0].substitutes[index].player.name,
                }
                :
                null;

            const away = lineups[1].substitutes.length >= index + 1 ?
                {
                    shirtNumber: lineups[1].substitutes[index].player.number,
                    name: lineups[1].substitutes[index].player.name,
                }
                :
                null;

            concatenatedData.push({
                home,
                away,
            });
        }

        return concatenatedData;
    };

    const firstSquadList = prepareFirstSquad(props.lineups);
    const benchList = prepareBench(props.lineups);

    const renderFirstSquadPlayer = (players: any): ReactNode => {
        return (
            <List.Item>
                <Row style={{ width: '100%' }}>
                    <Col className={styles.lineupHomePlayer} span={12}>{`${players.home?.shirtNumber ?? ''} ${players.home?.name ?? ''}`}</Col>
                    <Col className={styles.lineupAwayPlayer} span={12}>{`${players.away?.name ?? ''} ${players.away?.shirtNumber ?? ''}`}</Col>
                </Row>
            </List.Item>
        );
    };

    const renderBenchPlayer = (players: any): ReactNode => {
        return (
            <List.Item>
                <Row style={{ width: '100%' }}>
                    <Col className={classNames(styles.lineupHomePlayer, styles.lineupBenchPlayer)} span={12}>{`${players.home?.shirtNumber ?? ''} ${players.home?.name ?? ''}`}</Col>
                    <Col className={classNames(styles.lineupAwayPlayer, styles.lineupBenchPlayer)} span={12}>{`${players.away?.name ?? ''} ${players.away?.shirtNumber ?? ''}`}</Col>
                </Row>
            </List.Item>
        );
    };

    if (props.isLoading) {
        return <Skeleton />
    } else {
        return (
            <Card size="small" style={{ borderRadius: 0, textAlign: 'center' }}>
                <List
                    size="small"
                    dataSource={firstSquadList}
                    renderItem={renderFirstSquadPlayer}
                />
                <Divider />
                <List
                    size="small"
                    dataSource={benchList}
                    renderItem={renderBenchPlayer}
                />
            </Card>);
    }
}