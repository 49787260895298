import { Button, Form, Input, message } from 'antd';
import AuthenticationApi from 'Api/AuthenticationApi';
import { useAuth } from 'Authentication/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function ChangePassword() {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        marginTop: '200px',
    };

    const style: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        marginTop: '30px'
    };

    const formStyle: React.CSSProperties = {
        width: '400px'
    };

    type ChangePasswordFieldType = {
        currentPassword: string;
        newPassword: string;
        repeatedNewPassword: string;
    };

    const authenticationApi = new AuthenticationApi();
    const auth = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.isAuthenticated) {
            navigate('/login');
        }
    }, [auth.isAuthenticated, navigate]);

    const onSubmit = async (changePassword: ChangePasswordFieldType) => {
        const result = await authenticationApi.changePassword(changePassword.currentPassword, changePassword.newPassword);
        if (!result.ok) {
            messageApi.open({
                type: 'error',
                content: await result.text()
            });
        } else {
            messageApi.open({
                type: 'success',
                content: 'Password changed successfuly!',
            });

            setTimeout(() => {
                navigate('/');
            }, 2000);
        }
    };

    return (
        <div style={containerStyle}>
            <h1>Change password</h1>
            <div style={style}>
                {contextHolder}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    style={formStyle}
                >
                    <Form.Item<ChangePasswordFieldType>
                        label="Current password"
                        name="currentPassword"
                        rules={[
                            { required: true, message: 'Please input your current password!' }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item<ChangePasswordFieldType>
                        label="New password"
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Please input your new password!' },
                            { min: 8, message: 'Password must be minimum 8 characters long.' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item<ChangePasswordFieldType>
                        label="Repeat new password"
                        name="repeatedNewPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your new password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}