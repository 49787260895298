import { Button, Card, Col, Row, Skeleton, Modal, Form, Input, message, Divider, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { HighlightModel } from "Models/HighlightModel";
import { useEffect, useState } from "react";
import YouTube from 'react-youtube';
import { useMediaQuery } from 'react-responsive';
import FixturesApi from "Api/FixturesApi";
import sharedStyles from "./../../../Shared.module.scss";

export interface HighlightsProps {
    fixtureId: string;
    highlights?: HighlightModel[];
    isLoading: boolean;
}

export function Highlights(props: HighlightsProps) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [form] = Form.useForm();
    const fixturesApi = new FixturesApi();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setWidth(isMobile ? 320 : 768);
    }, [isMobile]);

    useEffect(() => {
        const aspectRatio = 16 / 9;
        const newHeight = Math.round(width / aspectRatio);
        setHeight(newHeight);
    }, [width]);

    useEffect(() => {
        for (const highlight of props.highlights ?? []) {
            extractVideoId(highlight.url);
        }
    }, [props.highlights]);

    const isYouTubeUrl = (url: string): boolean => {
        const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.*/;
        return youtubePattern.test(url);
    }

    const extractVideoId = (url: string) => {
        // eslint-disable-next-line
        const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        const match = url.match(regex);
        return match && match.length > 1 ? match[1] : '';
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onAddHighlight = async (highlight: HighlightModel) => {
        try {
            await fixturesApi.addHighlight(props.fixtureId, highlight);
            setIsModalOpen(false);
        } catch {
            messageApi.open({
                type: 'error',
                content: 'An error occured while adding highlight.',
            });
        }
    };

    const onDeleteHighligh = async (highlightId: string) => {
        const { confirm } = Modal;
        confirm({
            title: 'Are you sure you want to delete this highlight?',
            icon: <ExclamationCircleFilled />,
            okButtonProps: { style: { backgroundColor: sharedStyles.primaryColor } },
            onOk() {
                deleteHighlightInternal(props.fixtureId, highlightId);
            }
        });

        const deleteHighlightInternal = async (fixtureId: string, highlightId: string) => {
            try {
                await fixturesApi.deleteHighlight(fixtureId, highlightId);
            } catch {
                messageApi.open({
                    type: 'error',
                    content: 'An error occured while deleting highlight.',
                });
            }
        }
    }

    return (
        <>
            {contextHolder}
            <Modal title="Add Highlight" open={isModalOpen} onCancel={handleCancel} footer={null} width={700}>
                <Form
                    form={form}
                    onFinish={onAddHighlight}
                >
                    <Form.Item name="url" label="URL" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="name" label="Name" rules={[{ required: false }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            {props.isLoading ?
                <Skeleton />
                :
                <Card size="small" title="Highlights" style={{ borderRadius: 0 }}>
                    <Row>
                        <Col>
                            <Tooltip title="Add highlight">
                                <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'center' }}>
                        {props.highlights?.map(highlight => {
                            return (<div key={highlight.id}>
                                {highlight.name ?? <h1>{highlight.name}</h1>}
                                {isYouTubeUrl(highlight.url) ?
                                    <YouTube videoId={extractVideoId(highlight.url)} opts={{
                                        width: width,
                                        height: height
                                    }} />
                                    :
                                    <div>
                                        <a href={highlight.url} target="_blank" rel="noreferrer">{highlight.name ?? highlight.url}</a>
                                    </div>
                                }
                                <Button type='primary' danger={true} icon={<DeleteOutlined />}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onDeleteHighligh(highlight.id)
                                    }}>
                                    Delete
                                </Button>
                                <Divider />
                            </div>)
                        })}
                    </div>
                </Card>}
        </>
    );
}