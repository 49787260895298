import { useEffect, useState } from 'react';
import { Skeleton, Row, Col, Modal, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { AddFixture } from './AddFixture';
import { useSignalR } from 'SignalR/useSignalR';
import { IdModel } from 'Models/IdModel';
import styles from './Fixture.module.scss';
import classNames from 'classnames';
import UserFixtureModel from 'Models/UserFixtureModel';
import FixturesApi from 'Api/FixturesApi';
import { FixtureListItem } from './FixtureListItem';
import { mapDateTime } from 'Common/Formatters';

export function FixturesList() {
    const [fixtures, setFixtures] = useState(Array<UserFixtureModel>());
    const [filteredFixtures, setFilteredFixtures] = useState(Array<UserFixtureModel>());
    const [filterText, setFilterText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const fixturesApi = new FixturesApi();
    let delayInterval: any;

    useEffect(() => {
        fetchFixtures();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const filteredFixtures = fixtures
            .filter(f => f.awayTeam.name.toUpperCase().includes(filterText.toUpperCase()) || f.homeTeam.name.toUpperCase().includes(filterText.toUpperCase()));
        setFilteredFixtures(filteredFixtures);
    }, [fixtures, filterText]);

    useSignalR(message => {
        switch (message.messageType) {
            case 'FixtureAdded': {
                const userFixtureModel = message.content as UserFixtureModel;
                setFixtures(existingFixtures => {
                    if (existingFixtures.some(e => e.id === userFixtureModel.id)) {
                        return existingFixtures;
                    }

                    const newFixtures = [...existingFixtures, userFixtureModel];
                    newFixtures.sort((a, b) => a.startDateTimeUtc > b.startDateTimeUtc ? -1 : 1);

                    return newFixtures;
                });
                break;
            }

            case 'FixtureDeleted': {
                const idModel = message.content as IdModel;
                setFixtures(existingFixtures => {
                    const newFixtures = existingFixtures.filter(i => i.id !== idModel.id);
                    return newFixtures;
                })
                break;
            }
        }
    });

    const deleteItem = async (id: string) => {
        const { confirm } = Modal;
        confirm({
            title: 'Are you sure you want to delete this fixture?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                fixturesApi.deleteFixture(id);
            }
        });
    };

    const fetchFixtures = async () => {
        setIsLoading(true);
        const dataFromApi = await fixturesApi.getFixtures();
        setFixtures(dataFromApi);
        setIsLoading(false);
    };

    const groupByYearDescending = (fixtures: UserFixtureModel[]): Map<number, UserFixtureModel[]> => {
        const groupedByYear = fixtures.reduce((acc: Map<number, UserFixtureModel[]>, fixture: UserFixtureModel) => {
            const year = mapDateTime(fixture.startDateTimeUtc)?.getFullYear();
            if (year) {
                if (!acc.has(year)) {
                    acc.set(year, []);
                }
                acc.get(year)?.push(fixture);
            }
            return acc;
        }, new Map<number, UserFixtureModel[]>());

        return groupedByYear;
    }

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        clearInterval(delayInterval);
        delayInterval = setInterval(() => {
            clearInterval(delayInterval);
            setFilterText(value);
        }, 500);
    };

    return (
        <div className={classNames(styles.marginTop10, styles.marginLeft5)}>
            <Row>
                <Col flex="auto" ><h1>My journey</h1></Col>
                <Col flex="80px" style={{ alignSelf: 'center', marginRight: '5px' }}>
                    <AddFixture />
                </Col>
            </Row>
            <Row>
                <Input type="text" placeholder="Search..." size="large" onChange={onFilterChange} />
            </Row>
            {isLoading && <Skeleton active></Skeleton>}
            {!isLoading && Array.from(groupByYearDescending(filteredFixtures)).map(([year, fixturesByYear]) => <div key={year}>
                <h1>{`${year} (${fixturesByYear.length})`}</h1>
                {fixturesByYear.map(ev => <FixtureListItem key={ev.id} {...ev}
                    id={ev.id} venueName={ev?.venue?.name}
                    tournament={ev?.league?.name}
                    tournamentLogo={ev?.league?.logo}
                    homeTeam={ev?.homeTeam.name}
                    awayTeam={ev?.awayTeam.name}
                    homeTeamLogo={ev?.homeTeam.logo}
                    awayTeamLogo={ev?.awayTeam.logo}
                    startDateTime={mapDateTime(ev.startDateTimeUtc)}
                    onDeleteItem={deleteItem} onItemClicked={() => { }} />)}
            </div>)}
        </div>
    );
}