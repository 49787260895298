import StatisticsApi from "Api/StatisticsApi";
import { Avatar, Button, Card, List, Modal, Skeleton } from "antd";
import { BarChartOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TeamWithAttendace } from "Models/Statistics/TeamWithAttandance";
import sharedStyles from 'Shared.module.scss';

export function MostAttendedTeamsTile() {
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState<TeamWithAttendace[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const statisticsApi = new StatisticsApi();

    useEffect(() => {
        fetchStatistics();
        // eslint-disable-next-line
    }, []);

    const fetchStatistics = async () => {
        setIsLoading(true);
        const dataFromApi = await statisticsApi.getMostAttendedTeams();
        setStats(dataFromApi);
        setIsLoading(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const top10Teams = stats.slice(0, 10);

    const CategoryTick = (props: any) => {
        const correspondingStat = stats.find(s => s.id === props.payload.value);
        if (correspondingStat) {
            return (
                <g transform={`translate(${props.x-44},${props.y})`}>
                  <image xlinkHref={correspondingStat!.logo} x={0} y={0} height={isMobile? "24px": "32px"} width="88px" textAnchor="middle" />
                </g>
              )
        } else {
            return <span></span>
        }
    }

    return (
        <>
            <Card title="Most attended teams" extra={<Button onClick={showModal}>More</Button>}>
                {isLoading && <Skeleton.Node active={true}>
                    <BarChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
                </Skeleton.Node>}
                {!isLoading && <ResponsiveContainer minHeight={300} width="100%" height="100%" aspect={1}>
                    <BarChart data={top10Teams} margin={{ left: -40 }}>
                        <Bar dataKey="attendances">
                            {top10Teams.map(_ => <Cell />)}
                        </Bar>
                        <XAxis dataKey="id" interval={0} tick={<CategoryTick />} height={40}/>
                        <YAxis />
                        <Tooltip labelFormatter={(label, payload) => {
                            if (payload.length > 0) {
                                return payload[0].payload.name;
                            }
                        }}/>
                    </BarChart>
                </ResponsiveContainer>}
            </Card>
            <Modal title="Most attended teams" open={isModalOpen} onCancel={handleCancel} footer={null} width={700}>
                <div className={sharedStyles.appModal}>
                    <List itemLayout="horizontal" size="small" dataSource={stats} renderItem={(team, index) => (
                        <List.Item key={team.id}>
                            <List.Item.Meta
                                avatar={<Avatar src={team.logo} />}
                                title={team.name}
                            />
                            <div>
                                {team.attendances}
                            </div>
                        </List.Item>
                    )}>
                    </List>
                </div>
            </Modal>
        </>
    )
}