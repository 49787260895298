import { UserStorageKey } from "Authentication/AuthenticationProvider";
import UserModel from "Models/UserModel";

export default abstract class BaseApi {
    protected getToken() {
        let userString = localStorage.getItem(UserStorageKey);
        if (!userString) {
            userString = sessionStorage.getItem(UserStorageKey);
        }

        if (!userString) {
            return '';
        }

        const user = JSON.parse(userString) as UserModel;
        if (!user) {
            return '';
        }

        return `Bearer ${user.accessToken}`;
    }
}