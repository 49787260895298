import { Card, Col, List, Progress, Row, Skeleton } from "antd";
import { StatisticsByTeam } from "Models/StatisticsByTeamModel";
import { ReactNode } from "react";

export interface StatisticsProps {
    statistics: StatisticsByTeam[];
    isLoading: boolean;
    homeTeamId?: number;
    awayTeamId?: number;
}

interface CombinedStatistic {
    type: string;
    homeValue: any;
    awayValue: any;
}

export function Statistics(props: StatisticsProps) {
    const calculatePercentValues = (statistic: CombinedStatistic): number[] => {
        switch (statistic.type) {
            case "Ball Possession":
            case "expected_goals": {
                const homeValueNumber = parseFloat(statistic.homeValue);
                const awayValueNumber = parseFloat(statistic.awayValue);

                if (homeValueNumber === 0 && awayValueNumber === 0) {
                    return [50, 50];
                }

                const homeValuePercent = homeValueNumber / (homeValueNumber + awayValueNumber) * 100;
                const awayValuePercent = awayValueNumber / (homeValueNumber + awayValueNumber) * 100;

                return [homeValuePercent, awayValuePercent];
            }
            case "Passes %": {
                const homeValueNumber = parseFloat(statistic.homeValue);
                const awayValueNumber = parseFloat(statistic.awayValue);

                if (homeValueNumber === 0 && awayValueNumber === 0) {
                    return [50, 50];
                }

                return [homeValueNumber, awayValueNumber];
            }
            default: {
                const homeValueNumber = statistic.homeValue;
                const awayValueNumber = statistic.awayValue;

                if (homeValueNumber === 0 && awayValueNumber === 0) {
                    return [50, 50];
                }

                const homeValuePercent = homeValueNumber / (homeValueNumber + awayValueNumber) * 100;
                const awayValuePercent = awayValueNumber / (homeValueNumber + awayValueNumber) * 100;

                return [homeValuePercent, awayValuePercent];
            }
        }
    };

    const renderLabel = (statisticType: string) => {
        if (statisticType === "expected_goals") {
            return "Expected Goals";
        }

        return statisticType;
    }

    const renderProgress = (statisticType: string, homeValuePercent: number, awayValuePercent: number) => {
        switch (statisticType) {
            case "Passes %": {
                return (
                    <Row>
                        <Col span={12} style={{ paddingRight: 1 }}>
                            <Progress percent={100} success={{ percent: 100 - homeValuePercent, strokeColor: "white" }}
                                type={"line"} showInfo={false} strokeColor={homeValuePercent >= awayValuePercent ? "#ff4d4f" : "rgba(0,0,0,.6)"} strokeLinecap="square" />
                        </Col>
                        <Col span={12} style={{ paddingLeft: 1 }}>
                            <Progress percent={100} success={{ percent: awayValuePercent, strokeColor: awayValuePercent >= homeValuePercent ? "#ff4d4f" : "rgba(0,0,0,.6)" }}
                                type={"line"} showInfo={false} strokeColor="white" strokeLinecap="square" />
                        </Col>
                    </Row>
                );
            }
            default: {
                return (
                    <Row>
                        <Col span={12} style={{ paddingRight: 1 }}>
                            <Progress percent={100} success={{ percent: 100 - homeValuePercent, strokeColor: "white" }}
                                type={"line"} showInfo={false} strokeColor={homeValuePercent >= awayValuePercent ? "#ff4d4f" : "rgba(0,0,0,.6)"} strokeLinecap="square" />
                        </Col>
                        <Col span={12} style={{ paddingLeft: 1 }}>
                            <Progress percent={100} success={{ percent: awayValuePercent, strokeColor: awayValuePercent >= homeValuePercent ? "#ff4d4f" : "rgba(0,0,0,.6)" }}
                                type={"line"} showInfo={false} strokeColor="white" strokeLinecap="square" />
                        </Col>
                    </Row>
                );
            }
        }
    }

    const renderItem = (stat: CombinedStatistic): ReactNode => {
        const [homeValuePercent, awayValuePercent] = calculatePercentValues(stat);

        return (
            <List.Item style={{ justifyContent: 'start', display: 'block' }}>
                <Row style={{ width: '100%' }}>
                    <Col style={{ justifyContent: 'start', display: 'flex' }} span={8}>{stat.homeValue}</Col>
                    <Col style={{ justifyContent: 'center', display: 'flex' }} span={8}>{renderLabel(stat.type)}</Col>
                    <Col style={{ justifyContent: 'end', display: 'flex' }} span={8}>{stat.awayValue}</Col>
                </Row>
                {renderProgress(stat.type, homeValuePercent, awayValuePercent)}
            </List.Item>
        );
    }

    const combineStatistics = (): CombinedStatistic[] => {
        const results = new Map<string, CombinedStatistic>();
        for (const statisticsByTeam of props.statistics) {
            for (const statistic of statisticsByTeam.statistics) {
                if (!results.has(statistic.type)) {
                    results.set(statistic.type, { type: statistic.type, homeValue: 0, awayValue: 0 });
                }

                if (statisticsByTeam.teamId === props.homeTeamId && statistic.value && statistic.value !== null) {
                    results.get(statistic.type)!.homeValue = statistic.value;
                } else if (statisticsByTeam.teamId === props.awayTeamId && statistic.value && statistic.value !== null) {
                    results.get(statistic.type)!.awayValue = statistic.value;
                }
            }
        }

        return Array.from(results.values());
    }

    const combinedStatistics = combineStatistics();

    if (props.isLoading) {
        return <Skeleton />
    } else {
        return (<Card size="small" style={{ borderRadius: 0, textAlign: 'center' }}>
            <List
                size="small"
                dataSource={combinedStatistics}
                renderItem={renderItem}
            />
        </Card>);
    }
}