import { List } from "antd";
import classNames from "classnames";
import redCardIcon from 'Icons/redcard.svg';
import yellowCardIcon from 'Icons/yellowcard.svg';
import styles from './Event.module.scss';
import { formatTime } from "Common/Formatters";
import { EventModel } from "Models/EventModel";

export interface CardEventProps {
    event: EventModel;
    homeTeamId?: number;
    awayTeamId?: number;
}

export function CardEvent(props: CardEventProps) {
    const { event } = props;

    if (event.teamId === props.homeTeamId) {
        return (
            <List.Item style={{ justifyContent: 'start' }}>
                <span className={styles.marginRight5}>{formatTime(event.time)}</span>
                <img src={event.detail === "Yellow Card" ? yellowCardIcon : redCardIcon} alt='card'
                    className={classNames(styles.marginRight5, styles.eventLogo)} />
                <span className={styles.marginRight5}>{event.player?.name}</span>
            </List.Item>
        );
    } else if (event.teamId === props.awayTeamId) {
        return (
            <List.Item style={{ justifyContent: 'end' }}>
                <span className={styles.marginRight5}>{event.player?.name}</span>
                <img src={event.detail === "Yellow Card" ? yellowCardIcon : redCardIcon} alt='card'
                    className={classNames(styles.marginRight5, styles.eventLogo)} />
                <span className={styles.marginRight5}>{formatTime(event.time)}</span>
            </List.Item>
        );
    } else {
        return <></>;
    }
}