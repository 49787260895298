import { Avatar, Button, Col, Row, Tooltip } from "antd";
import { Link, useSearchParams } from 'react-router-dom';
import { CalendarOutlined, PushpinOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./Fixture.module.scss";
import { pad } from "Common/Formatters";
import { useMediaQuery } from "react-responsive";
import classnames from "classnames";
import { useEffect, useState } from "react";

export interface FixtureProps {
    id: string;
    tournamentLogo?: string;
    startDateTime?: Date;
    venueName?: string;
    homeTeam: string;
    awayTeam: string;
    homeTeamLogo?: string;
    awayTeamLogo?: string;
    homeScore: number;
    awayScore: number;
    onDeleteItem: (id: string) => void;
    onItemClicked: (id: string) => void;
    tournament: string;
}

export function FixtureListItem(props: FixtureProps) {
    const homeTeamWon = props.homeScore > props.awayScore;
    const awayTeamWon = props.awayScore > props.homeScore;
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const scrollYParameter = searchParams.get('scrollY');
        if (scrollYParameter) {
            const scrollY = parseInt(scrollYParameter);
            window.scrollTo(0, scrollY);
            searchParams.delete('scrollY');
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if (window) {
            const handleScroll = () => {
                const position = window.scrollY;
                setScrollPosition(position);
            };

            window.addEventListener("scroll", handleScroll);

            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    const desktopListItem = (<Link to={`/fixtures/${props.id}?returnUrl=/?scrollY=${scrollPosition}`} className={styles.linkWithoutStyle}>
        <Row className={styles.fixtureRow} onClick={() => props.onItemClicked(props.id)}>
            <Col xs={4} sm={4} className={styles.centerSelf}>
                <Tooltip title={props.tournament}>
                    <div style={{ width: 50, height: 50, textAlign: 'center' }}>
                        <img
                            alt='logo'
                            src={props.tournamentLogo}
                            height={46}
                            style={{ marginTop: 2 }}
                        />
                    </div>
                </Tooltip>
            </Col>
            <Col xs={0} sm={6} className={classnames(styles.centerSelf, styles.fontSize14)}>
                <div>
                    {(props.startDateTime &&
                        <div>
                            <Tooltip title='Date and time' placement='bottomLeft'><CalendarOutlined className={styles.icon} /></Tooltip>
                            <span>{`${pad(props.startDateTime.getDate(), 2)}.${pad(props.startDateTime.getMonth() + 1, 2)}.${props.startDateTime.getFullYear()} ${pad(props.startDateTime.getHours(), 2)}:${pad(props.startDateTime.getMinutes(), 2)}`}</span>
                        </div>)
                    }
                    {(props.venueName &&
                        <div>
                            <Tooltip title='Venue' placement='bottomLeft'><PushpinOutlined className={styles.icon} /></Tooltip>
                            <span>{props.venueName}</span>
                        </div>)}
                </div>
            </Col>
            <Col xs={14} sm={10} className={styles.centerSelf}>
                <div>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: homeTeamWon })}>
                        <Avatar src={props.homeTeamLogo} size={"small"} shape="square" />
                        {props.homeTeam}
                    </Row>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: awayTeamWon })}>
                        <Avatar src={props.awayTeamLogo} size={"small"} shape="square" />
                        {props.awayTeam}
                    </Row>
                </div>
            </Col>
            <Col xs={4} sm={2} className={styles.centerSelf}>
                <div>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: homeTeamWon })}>{props.homeScore}</Row>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: awayTeamWon })}>{props.awayScore}</Row>
                </div>
            </Col>
            <Col xs={2} sm={2} className={styles.lastColumn}>
                <div>
                    <Row>
                        <Tooltip title='Delete' placement='bottomLeft'>
                            <Button type='primary' danger={true} icon={<DeleteOutlined />}
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.onDeleteItem(props.id);
                                }} />
                        </Tooltip>
                    </Row>
                </div>
            </Col>
        </Row>
    </Link>);

    const mobileListItem = (<Link to={`/fixtures/${props.id}?returnUrl=${'/'}`} className={styles.linkWithoutStyle}>
        <Row className={styles.fixtureRow} onClick={() => props.onItemClicked(props.id)}>
            <Col xs={3} sm={4} className={styles.centerSelf}>
                <Tooltip title={props.tournament}>
                    <img
                        alt='logo'
                        src={props.tournamentLogo}
                        width={40}
                    />
                </Tooltip>
            </Col>
            <Col xs={5} sm={6} className={classnames(styles.centerSelf, styles.fontSize14)}>
                <div>
                    {(props.startDateTime &&
                        <div>
                            <span>{`${pad(props.startDateTime.getDate(), 2)}.${pad(props.startDateTime.getMonth() + 1, 2)}.${props.startDateTime.getFullYear()}`}</span>
                        </div>)
                    }
                </div>
            </Col>
            <Col xs={11} sm={10} className={styles.centerSelf}>
                <div>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: homeTeamWon })}>{props.homeTeam}</Row>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: awayTeamWon })}>{props.awayTeam}</Row>
                </div>
            </Col>
            <Col xs={3} sm={2} className={styles.centerSelf}>
                <div>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: homeTeamWon })}>{props.homeScore}</Row>
                    <Row className={classnames(styles.fontSize18, { [styles.fontBold]: awayTeamWon })}>{props.awayScore}</Row>
                </div>
            </Col>
            <Col xs={2} sm={2} className={styles.lastColumn}>
                <div>
                    <Row>
                        <Tooltip title='Delete' placement='bottomLeft'>
                            <Button type='primary' danger={true} icon={<DeleteOutlined />}
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.onDeleteItem(props.id);
                                }} />
                        </Tooltip>
                    </Row>
                </div>
            </Col>
        </Row>
    </Link>);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? mobileListItem : desktopListItem;
}