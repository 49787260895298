import { Routes, Route } from "react-router-dom";
import { MainLayout } from './MainLayout';
import { Home } from './Components/Home/Home';
import { NoMatch } from "./Navigation/NoMatch";
import { ConfigProvider } from "antd";
import sharedStyles from "./Shared.module.scss";
import { Fixture } from "./Components/Fixtures/Fixture";
import { Login } from "Components/Authentication/Login";
import AuthGuard from "Authentication/AuthGuard";
import { Register } from "Components/Authentication/Register";
import { ChangePassword } from "Components/Authentication/ChangePassword";
import { RequestPasswordReset } from "Components/Authentication/RequestPasswordReset";
import { PasswordReset } from "Components/Authentication/PasswordReset";

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: sharedStyles.primaryColor,
                },
                hashed: false
            }}>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route element={<AuthGuard />}>
                        <Route index element={<Home />} />
                    </Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/register" element={<Register />}></Route>
                    <Route path="/requestPasswordReset" element={<RequestPasswordReset />}></Route>
                    <Route path="/resetPassword" element={<PasswordReset />}></Route>
                    <Route element={<AuthGuard />}>
                        <Route path="/fixtures/:id" element={<Fixture />}></Route>
                    </Route>
                    <Route element={<AuthGuard />}>
                        <Route path="*" element={<NoMatch />} />
                    </Route>
                    <Route element={<AuthGuard />}>
                        <Route path="/changePassword" element={<ChangePassword />}></Route>
                    </Route>
                </Route>
            </Routes>
        </ConfigProvider >
    );
}

export default App;
