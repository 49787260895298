import { Skeleton, Button, Card, Pagination, Checkbox, Modal, Tooltip } from "antd";
import { UploadOutlined, CheckCircleOutlined, StopOutlined, DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import MediaApi from "Api/MediaApi";
import { useFileUpload } from "FileUpload/useFileUpload";
import MediaModel from "Models/MediaModel";
import { useEffect, useRef, useState } from "react";
import { useSignalR } from "SignalR/useSignalR";
import styles from './MediaGallery.module.scss';
import Lightbox, { Slide } from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface GalleryProps {
    type: string;
    ownerId?: string;
    isLoading: boolean;
    uploadButtonText?: string;
}

export function MediaGallery(props: GalleryProps) {
    const mediaPerPage = Math.floor(window.innerWidth / 75) > 17 ? 17 : Math.floor(window.innerWidth / 75) * 2;
    const [lightboxVisible, setLightboxVisible] = useState<boolean>(false);
    const [selectedMedia, setSelectedMedia] = useState<MediaModel | null>(null);

    const fileUpload = useFileUpload();
    const uploadButtonText = props.uploadButtonText ?? "Upload";
    const [media, setMedia] = useState<MediaModel[]>([]);

    const latestOwnerId = useRef(props.ownerId);
    latestOwnerId.current = props.ownerId;

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [checkedMedia, setCheckedMedia] = useState<MediaModel[]>([]);

    const getMediaUrls = async () => {
        if (props.ownerId) {
            const mediaApi = new MediaApi();
            const getMediaResult = await mediaApi.getMedia(props.type, props.ownerId);
            getMediaResult.sort((a, b) => a.name > b.name ? 1 : -1);
            setMedia(getMediaResult);
        }
    };

    useEffect(() => {
        getMediaUrls();
        // eslint-disable-next-line
    }, [props.ownerId]);

    useEffect(() => {
        if (media.length === 0) {
            setCheckedMedia([]);
        }
    }, [media]);

    useSignalR(message => {
        switch (message.messageType) {
            case 'MediaUploaded': {
                const mediaModel = message.content as MediaModel;
                if (mediaModel.ownerId === latestOwnerId.current && mediaModel.type === props.type) {
                    setMedia(existingMedia => {
                        const newMedia = [...new Set<MediaModel>([...existingMedia, mediaModel])];
                        newMedia.sort((a, b) => a.name > b.name ? 1 : -1);
                        return newMedia;
                    });
                }

                break;
            }

            case "MediaDeleted": {
                const mediaModel = message.content as MediaModel;
                if (mediaModel.ownerId === latestOwnerId.current && mediaModel.type === props.type) {
                    setMedia(existingMedia => {
                        const newMedia = existingMedia.filter(m => m.name !== mediaModel.name);
                        newMedia.sort((a, b) => a.name > b.name ? 1 : -1);
                        return newMedia;
                    });
                }

                break;
            }
        }
    });

    const openLightbox = (media: MediaModel) => {
        setSelectedMedia(media);
        setLightboxVisible(true);
    };

    const closeLightbox = () => {
        setSelectedMedia(null);
        setLightboxVisible(false);
    };

    const isImageUrl = (url: string): boolean => {
        const imageUrlRegex = /\.(jpeg|jpg|gif|png|bmp|svg|webp)$/i;
        const path = new URL(url).pathname;
        return imageUrlRegex.test(path);
    }

    const toSlides = (media: MediaModel[]): Slide[] => {
        return media.map(m => {
            if (isImageUrl(m.url)) {
                return {
                    type: "image",
                    src: m.url
                };
            } else {
                return {
                    type: "video",
                    sources: [
                        {
                            src: m.url,
                            type: m.mimeType
                        },
                    ],
                }
            }
        });
    };

    const skipAndTake = (array: MediaModel[], skipCount: number, takeCount: number) => {
        return array.slice(skipCount, skipCount + takeCount);
    }

    const handleCheckedMediaChange = (checkedItem: MediaModel) => {
        if (checkedMedia.includes(checkedItem)) {
            setCheckedMedia(checkedMedia.filter((i) => i !== checkedItem));
        } else {
            setCheckedMedia([...checkedMedia, checkedItem]);
        }
    };

    const onMediaDeleted = (checkedMedia: MediaModel[]) => {
        const { confirm } = Modal;
        confirm({
            title: 'Are you sure you want to delete selected media?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                const mediaApi = new MediaApi();
                for (const checkedMediaItem of checkedMedia) {
                    mediaApi.deleteMedia(checkedMediaItem.type, checkedMediaItem.ownerId, checkedMediaItem.name);
                }
            }
        });
    };

    return (
        <>
            {props.isLoading ?
                <Skeleton />
                :
                <Card size="small" title="Photos and videos" style={{ borderRadius: 0 }}>
                    <div className={styles.buttonsContainer}>
                        <Tooltip title={uploadButtonText}>
                            <Button icon={<UploadOutlined />} type="primary" onClick={() => fileUpload.uploadFile(props.type, props.ownerId!)}>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Select all">
                            <Button icon={<CheckCircleOutlined />} onClick={() => setCheckedMedia(media)} disabled={media.length === 0}>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Unselect all">
                            <Button icon={<StopOutlined />} onClick={() => setCheckedMedia([])} disabled={media.length === 0}>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Unselect all">
                            <Button type="primary" danger={true} icon={<DeleteOutlined />} disabled={checkedMedia.length === 0}
                                onClick={() => onMediaDeleted(checkedMedia)}>
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {skipAndTake(media, (currentPage - 1) * mediaPerPage, mediaPerPage).map((media, index) => (
                                <div key={index} className={styles.mediaListItem}>
                                    <Checkbox className={styles.checkButton} onChange={() => handleCheckedMediaChange(media)}
                                        checked={checkedMedia.includes(media)} />
                                    {isImageUrl(media.url) ? (
                                        // eslint-disable-next-line
                                        <LazyLoadImage onClick={() => openLightbox(media)} style={{ width: '60px', objectFit: 'cover' }}
                                            src={media.thumbnailUrl ?? media.url} alt={`Image ${index}`} />
                                    ) : (
                                        <video onClick={() => openLightbox(media)} style={{ width: '60px', objectFit: 'scale-down' }} src={media.url} controls={false} />
                                    )}
                                </div>
                            ))}
                            <Lightbox
                                plugins={[Video]}
                                open={lightboxVisible}
                                close={() => closeLightbox()}
                                slides={toSlides(media)}
                                index={!!selectedMedia ? media.indexOf(selectedMedia) : 0}
                                video={{
                                    disableRemotePlayback: true
                                }}
                            />
                        </div>
                        <Pagination current={currentPage} total={media.length}
                            onChange={(page) => setCurrentPage(page)} pageSize={mediaPerPage}
                            showSizeChanger={false} />
                    </div>
                </Card>}
        </>
    );
}