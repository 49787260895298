import { UploadFile } from 'antd/es/upload/interface';
import React, { useState, FC } from 'react';
import { FileUploadContext } from './FileUploadContext';

interface FileUploadProviderProps {
    children: React.ReactNode;
}

const FileUploadProvider: FC<FileUploadProviderProps> = ({ children }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    return (
        <FileUploadContext.Provider value={{ fileList, setFileList }} >
            {children}
        </FileUploadContext.Provider>
    );
};

export { FileUploadProvider };