import UserModel from 'Models/UserModel';
import React, { useState, FC, useEffect } from 'react';
import { AuthenticationContext } from './AuthenticationContex';

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

export const UserStorageKey = 'user';

const AuthenticationProvider: FC<AuthenticationProviderProps> = ({ children }) => {
    const getUser = () => {
        let userString = localStorage.getItem(UserStorageKey);
        if (userString) {
            return JSON.parse(userString) as UserModel;
        }

        userString = sessionStorage.getItem(UserStorageKey);
        if (userString) {
            return JSON.parse(userString) as UserModel;
        }

        return null;
    };

    const [user, setUser] = useState<UserModel | null>(getUser);

    useEffect(() => {
        if (user !== undefined && user !== null) {
            if (user.rememberMe) {
                localStorage.setItem(UserStorageKey, JSON.stringify(user));
            } else {
                sessionStorage.setItem(UserStorageKey, JSON.stringify(user));
            }
        } else {
            localStorage.removeItem(UserStorageKey);
            sessionStorage.removeItem(UserStorageKey);
        }
    }, [user]);

    return (
        <AuthenticationContext.Provider value={{ user, setUser }} >
            {children}
        </AuthenticationContext.Provider>
    );
};

export { AuthenticationProvider };