import { Button, Form, Input, message } from 'antd';
import AuthenticationApi from 'Api/AuthenticationApi';
import { useAuth } from 'Authentication/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function RequestPasswordReset() {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        marginTop: '200px',
    };

    const style: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        marginTop: '30px'
    };

    const formStyle: React.CSSProperties = {
        width: '400px'
    };

    type RequestPasswordResetFieldType = {
        mail: string;
    };

    const authenticationApi = new AuthenticationApi();
    const auth = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/');
        }
    }, [auth.isAuthenticated, navigate]);

    const onSubmit = async (requestPasswordReset: RequestPasswordResetFieldType) => {
        const result = await authenticationApi.requestPasswordReset(requestPasswordReset.mail);
        if (!result.ok) {
            messageApi.open({
                type: 'error',
                content: await result.text()
            });
        } else {
            messageApi.open({
                type: 'success',
                content: 'Password reset requested. Please check your email.',
            });
        }
    };

    return (
        <div style={containerStyle}>
            <h1>Request password reset</h1>
            <div style={style}>
                {contextHolder}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    style={formStyle}
                >
                    <Form.Item<RequestPasswordResetFieldType>
                        label="Email"
                        name="mail"
                        rules={[
                            { required: true, message: 'Please input your email!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}