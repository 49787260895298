import { UploadFile } from 'antd/es/upload/interface';
import React, { createContext } from 'react';

interface FileUploadContextType {
    fileList: UploadFile[];
    setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

const FileUploadContext = createContext<FileUploadContextType | undefined>(undefined);

export { FileUploadContext };