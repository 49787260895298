import { List } from "antd";
import classNames from "classnames";
import substitutionIcon from 'Icons/substitution.svg';
import styles from './Event.module.scss';
import { formatTime } from "Common/Formatters";
import { EventModel } from "Models/EventModel";

export interface SubstitutionEventProps {
    event: EventModel;
    homeTeamId?: number;
    awayTeamId?: number;
}

export function SubstitutionEvent(props: SubstitutionEventProps) {
    const { event } = props;

    if (event.teamId === props.homeTeamId) {
        return (
            <List.Item style={{ justifyContent: 'start' }}>
                <span className={styles.marginRight5}>{formatTime(event.time)}</span>
                <img src={substitutionIcon} alt='substitution' className={classNames(styles.marginRight5, styles.eventLogo)} />
                <span className={styles.marginRight5}>{event.assist!.name}</span>
                <span className={classNames(styles.marginRight5, styles.eventPlayerOut)}>{`(${event.player!.name})`}</span>
            </List.Item>
        );
    } else if (event.teamId === props.awayTeamId) {
        return (
            <List.Item style={{ justifyContent: 'end' }}>
                <span className={styles.marginRight5}>{event.assist!.name}</span>
                <span className={classNames(styles.marginRight5, styles.eventPlayerOut)}>{`(${event.player!.name})`}</span>
                <img src={substitutionIcon} alt='substitution' className={classNames(styles.marginRight5, styles.eventLogo)} />
                <span className={styles.marginRight5}>{formatTime(event.time)}</span>
            </List.Item>
        );
    }

    return <></>;
}