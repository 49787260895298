import { useEffect, useState } from 'react';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { SignalRMessage } from './SignalRMessage';
import { useAuth } from 'Authentication/useAuth';

export function useSignalR(callback: (message: SignalRMessage) => void) {
    const [connection, setConnection] = useState<HubConnection>();
    const hubUrl = `${process.env.REACT_APP_BASE_URL}/Hub`;
    const auth = useAuth();

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(hubUrl, {
                accessTokenFactory: () => auth.user?.accessToken ?? '',
                transport: HttpTransportType.LongPolling,
            })
            .build();

        setConnection(newConnection);
    }, [auth.user, auth.user?.accessToken, hubUrl]);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('ReceiveMessages', message => {
                        callback(JSON.parse(message));
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
        // eslint-disable-next-line
    }, [connection]);
}