import { Row, Col, Tooltip } from 'antd';
import { CalendarOutlined, PushpinOutlined } from '@ant-design/icons';
import styles from "./Fixture.module.scss";
import classNames from 'classnames';
import { pad } from 'Common/Formatters';
import { useEffect, useState } from 'react';

export interface FixtureHeaderProps {
    tournament?: string;
    homeTeam?: string;
    awayTeam?: string;
    homeScore?: number;
    awayScore?: number;
    startDateTime?: Date;
    venueName?: string;
    tournamentLogo?: string;
    homeTeamLogo?: string;
    awayTeamLogo?: string;
}

export function FixtureHeader(props: FixtureHeaderProps) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const largeScreenLayout = (
        <div className={styles.fixtureHeader}>
            <Row>
                <Col span={24} className={styles.content} >
                    {props.tournament ?? ''}
                </Col>
            </Row>
            <Row>
                <Col span={1}></Col>
                <Col md={1} xs={1}>
                    <img
                        alt={props.homeTeam}
                        src={props.homeTeamLogo}
                        width={60}
                    />
                </Col>
                <Col span={7} className={classNames(styles.content, styles.fontSize32)}>{props.homeTeam ?? ''}</Col>
                <Col span={6} className={classNames(styles.content, styles.fontSize32)}>{`${props.homeScore ?? ''}:${props.awayScore ?? ''}`}</Col>
                <Col md={1} xs={1}>
                    <img
                        alt={props.awayTeam}
                        src={props.awayTeamLogo}
                        width={60}
                    />
                </Col>
                <Col span={7} className={classNames(styles.content, styles.fontSize32)}>{props.awayTeam ?? ''}</Col>
                <Col span={1}></Col>
            </Row>
            <Row className={classNames(styles.content, styles.textCenter)}>
                {props.startDateTime &&
                    <div className={classNames(styles.marginRight10)}>
                        <Tooltip title='Date and time' placement='bottomLeft'>
                            <CalendarOutlined className={styles.icon} />
                            <span>{`${pad(props.startDateTime.getDate(), 2)}.${pad(props.startDateTime.getMonth() + 1, 2)}.${props.startDateTime.getFullYear()} ${pad(props.startDateTime.getHours(), 2)}:${pad(props.startDateTime.getMinutes(), 2)}`}</span>
                        </Tooltip>
                    </div>}
                {props.venueName &&
                    <div className={classNames(styles.marginRight10)}>
                        <Tooltip title='Venue' placement='bottomLeft'>
                            <PushpinOutlined className={styles.icon} />
                            <span>{props.venueName ?? ''}</span>
                        </Tooltip>
                    </div>}
            </Row>
        </div>
    );

    const smallScreenLayout = (
        <div className={styles.fixtureHeader}>
            <Row>
                <Col span={24} className={styles.content} >
                    {props.tournament ?? ''}
                </Col>
            </Row>
            <Row>
                <Col span={1}></Col>
                <Col span={7} className={classNames(styles.content)}>
                    <div>
                        <Tooltip title={props.tournament}>
                            <img
                                alt={props.homeTeam}
                                src={props.homeTeamLogo}
                                width={40}
                            />
                        </Tooltip>
                    </div>
                    <div>{props.homeTeam ?? ''}</div>
                </Col>
                <Col span={8} className={classNames(styles.content, styles.fontSize32)}>{`${props.homeScore ?? ''}:${props.awayScore ?? ''}`}</Col>
                <Col span={7} className={classNames(styles.content)}>
                    <div>{props.awayTeam ?? ''}</div>
                    <div>
                        <Tooltip title={props.tournament}>
                            <img
                                alt={props.awayTeam}
                                src={props.awayTeamLogo}
                                width={40}
                            />
                        </Tooltip>
                    </div>
                </Col>
                <Col span={1}></Col>
            </Row>
            <Row style={{ marginLeft: '4px', marginRight: '4px' }}>
                <Col span={12} className={classNames(styles.content, styles.textCenter)}>
                    <div className={styles.marginRight10}>
                        <Tooltip title='Date and time' placement='bottomLeft'>
                            <CalendarOutlined className={styles.icon} />
                            <span>{props.startDateTime ? `${pad(props.startDateTime.getDate(), 2)}.${pad(props.startDateTime.getMonth() + 1, 2)}.${props.startDateTime.getFullYear()} ${pad(props.startDateTime.getHours(), 2)}:${pad(props.startDateTime.getMinutes(), 2)}` : ''}</span>
                        </Tooltip>
                    </div>
                </Col>
                <Col span={12} className={classNames(styles.content, styles.textCenter)}>
                    {props.venueName &&
                        <div className={styles.marginRight10}>
                            <Tooltip title='Venue' placement='bottomLeft'>
                                <PushpinOutlined className={styles.icon} />
                                <span>{props.venueName ?? ''}</span>
                            </Tooltip>
                        </div>}
                </Col>
            </Row>
        </div>
    );

    return (
        <div className="App">
            {isSmallScreen ? smallScreenLayout : largeScreenLayout}
        </div>
    );
}