import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, DatePicker, DatePickerProps, List, Input, Row, Col, InputRef, Skeleton, message } from 'antd';
import React from 'react';
import styles from './Fixture.module.scss';
import sharedStyles from 'Shared.module.scss';
import ExternalFixture from 'Models/ExternalFixtureModel';
import FixturesApi from 'Api/FixturesApi';

export function AddFixture() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [externalFixtures, setExternalFixtures] = useState(Array<ExternalFixture>());
    const [filteredExternalFixtures, setFilteredExternalFixtures] = useState(Array<ExternalFixture>());
    const [isLoading, setIsLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const fixturesApi = new FixturesApi();
    const inputRef = React.createRef<InputRef>();
    let delayInterval: any;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onDateChange: DatePickerProps['onChange'] = async (_, dateString) => {
        if (dateString) {
            try {
                setIsLoading(true);
                const dataFromApi = await fixturesApi.findFixturesOnDate(dateString);
                setExternalFixtures(dataFromApi);

                const searchText = inputRef.current?.input?.value ?? '';
                setFilteredExternalFixtures(dataFromApi
                    .filter(e => e.homeTeam.toLowerCase().includes(searchText.toLowerCase()) || e.awayTeam.toLowerCase().includes(searchText.toLowerCase())));
            } catch (e) {
                setExternalFixtures([]);
                setFilteredExternalFixtures([]);

                messageApi.open({
                    type: 'error',
                    content: 'An error occured while fetching fixtures.',
                });
            }
            finally {
                setIsLoading(false);
            }
        } else {
            setExternalFixtures([]);
            setFilteredExternalFixtures([]);
        }
    };

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value;
        clearInterval(delayInterval);
        delayInterval = setInterval(() => {
            clearInterval(delayInterval);
            setFilteredExternalFixtures(externalFixtures
                .filter(e => e.homeTeam.toLowerCase().includes(searchText.toLowerCase()) || e.awayTeam.toLowerCase().includes(searchText.toLowerCase())));
        }, 500);
    };

    const onSelected = async (id: number) => {
        try {
            await fixturesApi.addFixture(id);
            setIsModalOpen(false);
        } catch {
            messageApi.open({
                type: 'error',
                content: 'An error occured while adding fixture.',
            });
        }
    };

    return (
        <>
            {contextHolder}
            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Add
            </Button>
            <Modal title="Add fixture" open={isModalOpen} onCancel={handleCancel} footer={null} width={700}>
                <div className={sharedStyles.appModal}>
                    <Row>
                        <Col>
                            <DatePicker onChange={onDateChange} placeholder="1. Select date" />
                        </Col>
                        <Col>
                            <Input ref={inputRef} onChange={onFilterChange} placeholder="2. Find a game" />
                        </Col>
                    </Row>
                    {!isLoading &&
                        <List itemLayout="horizontal" size="small" dataSource={filteredExternalFixtures} renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={`${item.homeTeam} ${item.homeScore}:${item.awayScore} ${item.awayTeam}`}
                                    description={item.tournament}
                                />
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => onSelected(item.id)}>Add</Button>
                            </List.Item>
                        )}>
                        </List>}
                    {isLoading && Array.from({ length: 10 }, (_, i) => i + 1)
                        .map(i => <Skeleton.Input key={i} className={styles.marginTop10} active size='large' block={true} />)}
                </div>
            </Modal>
        </>
    )
}