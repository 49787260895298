import { HighlightModel } from "Models/HighlightModel";
import BaseApi from "./BaseApi";
import UserFixtureModel from "Models/UserFixtureModel";
import ExternalFixture from "Models/ExternalFixtureModel";

export default class FixturesApi extends BaseApi {
    private baseUrl = `${process.env.REACT_APP_BASE_URL}/api`;

    public findFixturesOnDate(dateTime: string): Promise<Array<ExternalFixture>> {
        return fetch(`${this.baseUrl}/Fixtures/external?` + new URLSearchParams({ dateTime: dateTime }), {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public addFixture(id: number): Promise<void> {
        return fetch(`${this.baseUrl}/Fixtures`, {
            method: 'POST',
            headers: {
                Authorization: this.getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: id })
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }
        });
    }

    public getFixtures(): Promise<Array<UserFixtureModel>> {
        return fetch(`${this.baseUrl}/Fixtures`, {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public getFixture(id: string): Promise<UserFixtureModel> {
        return fetch(`${this.baseUrl}/Fixtures/${id}`, {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public deleteFixture(id: string): Promise<boolean> {
        return fetch(`${this.baseUrl}/Fixtures/${id}`, {
            headers: {
                Authorization: this.getToken(),
            },
            method: 'Delete'
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return true;
        });
    }

    public addHighlight(fixtureId: string, highlight: HighlightModel): Promise<void> {
        return fetch(`${this.baseUrl}/Fixtures/${fixtureId}/Highlights`, {
            method: 'POST',
            headers: {
                Authorization: this.getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(highlight)
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }
        });
    }

    public deleteHighlight(fixtureId: string, highlightId: string): Promise<void> {
        return fetch(`${this.baseUrl}/Fixtures/${fixtureId}/Highlights/${highlightId}`, {
            method: 'DELETE',
            headers: {
                Authorization: this.getToken(),
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }
        });
    }
}