import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Button, Row, Col, Tabs, TabsProps, Skeleton } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import sharedStyles from "Shared.module.scss";
import { FixtureHeader } from "./FixtureHeader";
import { Events } from "./Events/Events";
import { Lineups } from "./Lineups/Lineups";
import { Statistics } from "./Statistics/Statistics";
import styles from './Fixture.module.scss';
import { useEffect, useState } from "react";
import UserFixtureModel from "Models/UserFixtureModel";
import { Highlights } from "./Highlights/Highlights";
import { useSignalR } from "SignalR/useSignalR";
import { IdModel } from "Models/IdModel";
import { MediaGallery } from "Components/Shared/MediaGallery";
import FootballFixturesApi from "Api/FixturesApi";
import { mapDateTime } from "Common/Formatters";

export function Fixture() {
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const returnUrl = searchParams.get('returnUrl');
    const [isLoading, setIsLoading] = useState(false);
    const [userFixture, setUserFixture] = useState<UserFixtureModel>();
    const navigate = useNavigate();
    const footballFixturesApi = new FootballFixturesApi();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFixture();
        // eslint-disable-next-line
    }, []);

    const fetchFixture = async () => {
        setIsLoading(true);
        try {
            const dataFromApi = await footballFixturesApi.getFixture(id!);
            setUserFixture(dataFromApi);
        } catch (error) {
            navigate(returnUrl ?? '/');
        } finally {
            setIsLoading(false);
        }
    };

    useSignalR(message => {
        switch (message.messageType) {
            case 'FixtureUpdated': {
                const userFixtureModel = message.content as UserFixtureModel;
                setUserFixture(userFixtureModel);
                break;
            }
            case 'FixtureDeleted': {
                const idModel = message.content as IdModel;
                if (idModel.id === id) {
                    navigate(returnUrl ?? '/');
                }
                break;
            }
        }
    });

    const items: TabsProps['items'] = [
        {
            key: 'Events',
            label: 'Events',
            children: <Events events={userFixture?.events ?? []} homeTeamId={userFixture?.homeTeam?.id}
                awayTeamId={userFixture?.awayTeam?.id} isLoading={isLoading} />,
        },
        {
            key: 'Lineups',
            label: 'Lineups',
            children: <Lineups lineups={userFixture?.lineups ?? []} isLoading={isLoading} />,
        },
        {
            key: 'Statistics',
            label: 'Statistics',
            children: <Statistics statistics={userFixture?.statistics ?? []} homeTeamId={userFixture?.homeTeam?.id}
                awayTeamId={userFixture?.awayTeam?.id} isLoading={isLoading} />,
        },
    ];

    return (
        <div className={styles.marginTop10} >
            <Link to={returnUrl ?? '/'} className={sharedStyles.linkWithoutStyle}>
                <Button type="primary" icon={<ArrowLeftOutlined />}>
                    Back
                </Button>
            </Link>
            <FixtureHeader
                {...userFixture}
                tournament={userFixture?.league?.name}
                homeTeam={userFixture?.homeTeam.name}
                awayTeam={userFixture?.awayTeam.name}
                homeTeamLogo={userFixture?.homeTeam.logo}
                awayTeamLogo={userFixture?.awayTeam.logo}
                venueName={userFixture?.venue?.name}
                startDateTime={mapDateTime(userFixture?.startDateTimeUtc)}
            />
            <Row className={styles.marginTop10}>
                <Col xs={24}>
                    <MediaGallery type="Fixture" ownerId={userFixture?.id} isLoading={isLoading} />
                </Col>
            </Row>
            <Row className={styles.marginTop10}>
                <Col xs={24}>
                    <Highlights
                        highlights={userFixture?.highlights}
                        isLoading={isLoading}
                        fixtureId={userFixture?.id ?? ''} />
                </Col>
            </Row>
            <Row className={styles.marginTop10}>
                <Col span={24}>
                    {isLoading ? <Skeleton /> : <Tabs centered={true} defaultActiveKey="Events" items={items} />}
                </Col>
            </Row>
        </div>
    );
}