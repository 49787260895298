import { Col, Row } from "antd";
import { MostAttendedLeaguesTile } from "./MostAttendedLeaguesTile";
import { MostAttendedTeamsTile } from "./MostAttendedTeamsTile";
import { MostFrequentlySeenPlayersTile } from "./MostFrequentlySeenPlayersTile";

export function StatisticsDashboard() {
    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <MostAttendedLeaguesTile />
            </Col>
            <Col xs={24} md={12}>
                <MostAttendedTeamsTile />
            </Col>
            <Col xs={24} md={12}>
                <MostFrequentlySeenPlayersTile />
            </Col>
        </Row>
    );
}