import { useAuth } from 'Authentication/useAuth';
import { FixturesList } from '../Fixtures/FixturesList';
import { Tabs } from 'antd';
import { StatisticsDashboard } from 'Components/Statistics/StatisticsDashboard';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function Home() {
    const auth = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
        const activeTabParameter = searchParams.get('tab');
        if (activeTabParameter) {
            setActiveTab(activeTabParameter);
        } else {
            setActiveTab("fixtures");
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (activeTab !== "") {
            searchParams.set("tab", activeTab);
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line
    }, [activeTab]);

    const handleTabOnChange = (activeKey: string) => {
        if (activeKey !== "") {
            setActiveTab(activeKey);
        }
    };

    return (
        auth.isAuthenticated ?
            <>
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabOnChange}
                    centered
                    size='large'
                    type='line'
                    items={[
                        {
                            key: "fixtures",
                            label: "Fixtures",
                            children: <FixturesList />,
                        },
                        {
                            key: "statistics",
                            label: "Statistics",
                            children: <StatisticsDashboard />
                        },
                        {
                            key: "feed",
                            label: "Feed",
                            children: <span>Social media coming soon.</span>
                        }
                    ]}
                />
            </>
            :
            <span>Fixtures available after logging in.</span>
    )
}