import UserModel from "Models/UserModel";
import BaseApi from "./BaseApi";

export default class AuthenticationApi extends BaseApi {
    private baseUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication`;

    public login(username: string, password: string): Promise<UserModel> {
        return fetch(`${this.baseUrl}/Authenticate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public register(username: string, mail: string, password: string): Promise<Response> {
        return fetch(`${this.baseUrl}/Register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, mail, password })
        });
    }

    public changePassword(currentPassword: string, newPassword: string): Promise<Response> {
        return fetch(`${this.baseUrl}/ChangePassword`, {
            method: 'POST',
            headers: {
                Authorization: this.getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ currentPassword, newPassword })
        });
    }

    public requestPasswordReset(mail: string): Promise<Response> {
        return fetch(`${this.baseUrl}/RequestPasswordReset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mail })
        });
    }

    public resetPassword(resetToken: string, newPassword: string): Promise<Response> {
        return fetch(`${this.baseUrl}/PasswordReset?resetToken=${resetToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ newPassword })
        });
    }
}