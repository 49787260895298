import UserModel from "Models/UserModel";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "./AuthenticationContex";

export function useAuth() {
    const { user, setUser } = useContext(AuthenticationContext)!;

    const navigate = useNavigate();

    const signInRedirect = () => {
        navigate('/login');
    }

    const signIn = (loggedInUser: UserModel) => {
        setUser(loggedInUser);
    };

    const signOut = () => {
        setUser(null);
    };

    return {
        user,
        isAuthenticated: !!user?.accessToken,
        signInRedirect,
        signIn,
        signOut,
    }
}