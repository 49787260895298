import { TimeModel } from "Models/TimeModel";

export function formatTime(time: TimeModel): string {
    if (time.elapsed <= 0) {
        return '-';
    } else {
        if (!time.extra) {
            return time.elapsed.toString();
        } else {
            return `${time.elapsed} + ${time.extra}`;
        }
    }
};

export function mapDateTime(stringDateTime?: string): Date | undefined {
    if (stringDateTime) {
        return new Date(stringDateTime);
    }
};

export function pad(num: number, size: number): string {
    let stringNum = num.toString();
    while (stringNum.length < size) {
        stringNum = "0" + num;
    }
    return stringNum;
}