import MediaModel from "Models/MediaModel";
import BaseApi from "./BaseApi";

export default class MediaApi extends BaseApi {
    private baseUrl = `${process.env.REACT_APP_BASE_URL}/api`;

    public uploadMedia(media: File, type: string, ownerId: string): Promise<Response> {
        const formData = new FormData();
        formData.append('file', media);
        return fetch(`${this.baseUrl}/Media/${type}/${ownerId}`, {
            method: 'POST',
            headers: {
                Authorization: this.getToken()
            },
            body: formData
        });
    }

    public getMedia(type: string, ownerId: string): Promise<Array<MediaModel>> {
        return fetch(`${this.baseUrl}/Media/${type}/${ownerId}`, {
            headers: {
                Authorization: this.getToken(),
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return response.json();
        });
    }

    public deleteMedia(type: string, ownerId: string, name: string): Promise<boolean> {
        return fetch(`${this.baseUrl}/Media/${type}/${ownerId}/${name}`, {
            headers: {
                Authorization: this.getToken(),
            },
            method: 'Delete'
        }).then(response => {
            if (!response.ok) {
                throw new Error();
            }

            return true;
        });
    }
}