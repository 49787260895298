import { Card, List, Skeleton } from 'antd';
import { EventModel } from 'Models/EventModel';
import { ReactNode } from 'react';
import { CardEvent } from './CardEvent';
import { GoalEvent } from './GoalEvent';
import { SubstitutionEvent } from './SubstitutionEvent';

export interface EventsProps {
    events: Array<EventModel>;
    isLoading: boolean;
    homeTeamId?: number;
    awayTeamId?: number;
}

export function Events(props: EventsProps) {
    const renderItem = (event: EventModel): ReactNode => {
        switch (event.type) {
            case "Card": {
                return (<CardEvent {...props} event={event} />);
            }

            case "Goal": {
                return (<GoalEvent {...props} event={event} />)
            }

            case "subst": {
                return (<SubstitutionEvent {...props} event={event} />);
            }
        }

        return <></>;
    };

    if (props.isLoading) {
        return <Skeleton />
    } else {
        const sortedEvents = props.events.sort((a, b) => a.time.elapsed + (a.time.extra ?? 0) - b.time.elapsed - (b.time.extra ?? 0));
        return (
            <Card size="small" style={{ borderRadius: 0, textAlign: 'center' }}>
                <List
                    size="small"
                    dataSource={sortedEvents}
                    renderItem={renderItem}
                />
            </Card>);
    }
}