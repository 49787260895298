import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Layout, Col, Row, Button, Dropdown, MenuProps } from 'antd';
import { UserOutlined, LoginOutlined, LogoutOutlined, ReloadOutlined, DownOutlined } from '@ant-design/icons';
import styles from "./MainLayout.module.scss";
import { useAuth } from 'Authentication/useAuth';
import { FileUpload } from 'FileUpload/FileUpload';
import { ItemType } from 'antd/es/menu/hooks/useItems';

const { Header, Footer, Content } = Layout;

export function MainLayout() {
    const contentStyle: React.CSSProperties = {
        minHeight: '90vh',
    };

    const footerStyle: React.CSSProperties = {
        textAlign: 'center',
    };

    const items: MenuProps['items'] = [
        {
            key: 'changePassword',
            label: (
                <span>Change password</span>
            ),
            icon: <ReloadOutlined />
        },
        {
            key: 'logout',
            label: (
                <span>Logout</span>
            ),
            icon: <LogoutOutlined />
        },
    ];

    const auth = useAuth();
    const navigate = useNavigate();

    const menuProps = {
        items,
        onClick: (item: ItemType) => {
            switch (item?.key) {
                case 'logout': {
                    auth.signOut();
                    break;
                }
                case 'changePassword': {
                    navigate('/changePassword');
                    break;
                }
            }
        }
    };

    return (
        <>
            <Layout>
                <Header className={styles.applicationHeader}>
                    <Row>
                        <Col flex="100px">
                            <Link to="/">
                                <img src='/logo.png' className={styles.logo} alt='logo' />
                            </Link>
                        </Col>
                        <Col flex="auto"></Col>
                        <Col flex="100px" className={styles.marginRight5} style={{ alignSelf: 'center' }}>
                            {auth.isAuthenticated ?
                                <Dropdown.Button
                                    menu={menuProps}
                                    icon={<DownOutlined />}
                                >
                                    <UserOutlined /><span>{auth.user?.username}</span>
                                </Dropdown.Button>
                                :
                                <Button icon={<LoginOutlined />} onClick={() => void auth.signInRedirect()}>Login</Button>}
                        </Col>
                    </Row>
                </Header>
                <Layout className={styles.layout}>
                    <Row>
                        <Col xs={0} sm={0} md={0} lg={4}>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <Content style={contentStyle}>
                                <Outlet />
                            </Content>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={4}>
                        </Col>
                    </Row>
                </Layout>
                <Footer style={footerStyle}>GSoftware ©2024</Footer>
            </Layout>
            <div style={{ position: "fixed", bottom: "10px", right: "10px" }}>
                <FileUpload />
            </div>
        </>
    );
}