import StatisticsApi from "Api/StatisticsApi";
import { SeenPlayer } from "Models/Statistics/SeenPlayer";
import { Button, Card, Input, List, Modal, Skeleton } from "antd";
import { BarChartOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import sharedStyles from 'Shared.module.scss';

export function MostFrequentlySeenPlayersTile() {
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState<SeenPlayer[]>([]);
    const [filteredStats, setFilteredStats] = useState<SeenPlayer[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const statisticsApi = new StatisticsApi();
    let delayInterval: any;

    useEffect(() => {
        fetchStatistics();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const filteredStats = stats
            .filter(p => p.name.toUpperCase().includes(filterText.toUpperCase()) || p.teams.some(t => t.toUpperCase().includes(filterText.toUpperCase())));
        setFilteredStats(filteredStats);
    }, [stats, filterText]);

    const fetchStatistics = async () => {
        setIsLoading(true);
        const dataFromApi = await statisticsApi.getMostFrequentlySeenPlayers();
        setStats(dataFromApi);
        setIsLoading(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        clearInterval(delayInterval);
        delayInterval = setInterval(() => {
            clearInterval(delayInterval);
            setFilterText(value);
        }, 500);
    };

    const top10Players = stats.slice(0, 10);

    return (
        <>
        <Card title="Most frequently seen players" extra={<Button onClick={showModal}>More</Button>}>
            {isLoading && <Skeleton.Node active={true}>
                <BarChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
            </Skeleton.Node>}
            {!isLoading && <ResponsiveContainer minHeight={300} width="100%" height="100%" aspect={1}>
                <BarChart data={top10Players} margin={{ left: -40 }}>
                    <Bar dataKey="seen">
                        {top10Players.map(_ => <Cell />)}
                    </Bar>
                    <XAxis dataKey="name" interval={0} height={150} angle={-60} textAnchor="end" fontSize={16} />
                    <YAxis />
                    <Tooltip labelFormatter={(label, payload) => {
                        if (payload.length > 0) {
                            return `${payload[0].payload.name} (${payload[0].payload.teams.join(", ")})`;
                        }
                    }}/>
                </BarChart>
            </ResponsiveContainer>}
        </Card>
        <Modal title="Most frequently seen players" open={isModalOpen} onCancel={handleCancel} footer={null} width={700}>
            <div className={sharedStyles.appModal}>
                <Input type="text" placeholder="Search..." size="large" onChange={onFilterChange} />
                <List itemLayout="horizontal" size="small" dataSource={filteredStats} renderItem={(player, index) => (
                    <List.Item key={player.id}>
                        <List.Item.Meta
                            title={player.name}
                            description={player.teams.join(", ")}
                        />
                        <div>
                            {player.seen}
                        </div>
                    </List.Item>
                )}>
                </List>
            </div>
        </Modal>
        </>
    )
}