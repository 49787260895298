import { Button, Col, Progress, Row, Tooltip, Upload, UploadFile } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { useFileUpload } from "./useFileUpload";
import styles from "./FileUpload.module.scss";
import { ReactElement, ReactNode } from "react";
import { UploadFileStatus } from "antd/es/upload/interface";

export function FileUpload() {
    const fileUpload = useFileUpload();

    const trimText = (text: string) => {
        if (text.length > 10) {
            return `${text.slice(0, 10)}...`;
        }

        return text;
    };

    const convertFileStatusToProgressStatus = (uploadFileStatus: UploadFileStatus | undefined) => {
        switch (uploadFileStatus) {
            case "done":
            case "success": {
                return "success";
            }
            case "error": {
                return "exception";
            }
            default: {
                return "active";
            }
        }
    };

    const customItemRenderer = (originNode: ReactElement, file: UploadFile, fileList: UploadFile<any>[]): ReactNode => {
        return <Row className={styles.customUploadFileWrapper}>
            <Col span={6} className={styles.customUploadFileImageWrapper}>
                <img alt={file.name} className={styles.customUploadFileImage} src={file.url} />
            </Col>
            <Col span={2}></Col>
            <Col span={16}>
                <Row>
                    <Tooltip title={file.name}>
                        <span >{trimText(file.name)}</span>
                    </Tooltip>
                </Row>
                <Row>
                    <Progress status={convertFileStatusToProgressStatus(file.status)} percent={100} showInfo={false} />
                </Row>
            </Col>
        </Row>
    }

    return (
        <Upload
            listType="picture"
            fileList={[...fileUpload.fileList]}
            beforeUpload={(file) => false}
            multiple={true}
            showUploadList={{ showRemoveIcon: false }}
            itemRender={customItemRenderer}
        >
            {<Button style={{ visibility: 'collapse', display: 'none' }} id="button-upload-files" icon={<UploadOutlined />} />}
        </Upload>
    )
}