import { UploadFile } from "antd";
import MediaApi from "Api/MediaApi";
import { useContext } from "react";
import { FileUploadContext } from "./FileUploadContext";

export function useFileUpload() {
    const { fileList, setFileList } = useContext(FileUploadContext)!;

    const isImage = (file: File) => {
        return file.type.startsWith("image/");
    };

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result?.toString() || '');
            reader.onerror = error => reject(error);
        })
    };

    const generateGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function (c) {
                // eslint-disable-next-line
                var uuid = Math.random() * 16 | 0, v = c === 'x' ? uuid : (uuid & 0x3 | 0x8);
                return uuid.toString(16);
            });
    }

    const uploadFile = (type: string, ownerId: string) => {
        const uploadInputs = document.getElementsByTagName("input");
        const uploadInput = uploadInputs[uploadInputs.length - 1];
        uploadInput!.onchange = async (e) => {
            const mediaApi = new MediaApi();
            const target = e.target as HTMLInputElement;
            const files = [...target.files!];
            const uploadFiles = await Promise.all(files.map(async file => {
                const uploadFile: UploadFile = {
                    uid: generateGuid(),
                    name: file.name,
                    status: "uploading",
                };

                if (isImage(file)) {
                    const base64Image = await fileToBase64(file);
                    uploadFile.url = base64Image;
                }

                return uploadFile;
            }));

            setFileList(fileList => [...fileList, ...uploadFiles]);

            for (const uploadFile of uploadFiles) {
                const file = files.find(f => f.name === uploadFile.name);
                const response = await mediaApi.uploadMedia(file!, type, ownerId);
                setFileList(fileList => {
                    const fileListCopy = [...fileList];
                    const existingFile = fileListCopy.find(f => f.uid === uploadFile.uid);
                    existingFile!.status = response.ok ? 'done' : 'error';
                    return fileListCopy;
                });

                setTimeout(() => {
                    setFileList(fileList => {
                        const fileListCopy = fileList.filter(f => f.uid !== uploadFile.uid);
                        return fileListCopy;
                    });
                }, 5000);
            }
        };

        const uploadButton = document.getElementById("button-upload-files");
        if (uploadButton !== undefined && uploadButton !== null) {
            uploadButton.click();
        }
    };

    return {
        uploadFile,
        fileList,
        setFileList,
    };
}