import UserModel from 'Models/UserModel';
import React, { createContext } from 'react';

interface AuthenticationContextType {
  user: UserModel | null;
  setUser: React.Dispatch<React.SetStateAction<UserModel | null>>;
}

const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined);

export { AuthenticationContext };